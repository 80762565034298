import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


//引入element-ui框架
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css"
Vue.use(ElementUI);


//引入axios插件
import axios from "axios";
Vue.prototype.$axios = axios;

Vue.config.productionTip = false

new Vue({
  axios,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
