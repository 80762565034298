import req from "../utils/req"


//获取产品经理显示数据
export function getOrderInfoToProductManager_api(param) {
    return req.get("/otaHotel/order/getOrderInfoToProductManager", param)
}
//获取酒店显示数据
export function getOrderInfoToHotel_api(param) {
    return req.get("/otaHotel/order/getOrderInfoToHotel", param)
}
//操作接受
export function acceptH5_api(param) {
    return req.get("/otaHotel/order/acceptH5", param)
}
//操作拒绝
export function refuseH5_api(param) {
    return req.get("/otaHotel/order/refuseH5", param)
}